
let tokenClient;
let accessToken = null;
let pickerInited = false;
let gisInited = false;
const scope =   'https://www.googleapis.com/auth/drive.file ' +
    'https://www.googleapis.com/auth/drive.metadata ' +
    'https://www.googleapis.com/auth/drive.readonly';

function onApiLoad() {
    gapi.load('picker', onPickerApiLoad);
}

function onPickerApiLoad() {
    pickerInited = true;
}

function gisLoaded() {
    tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: '631706163465-ajtr5v8vhjpiv96rqk89u97q80tba2nh.apps.googleusercontent.com',
        scope: scope,
        callback: '',
    });
    gisInited = true;
}
function createPicker() {
    const showPicker = () => {
        // TODO(developer): Replace with your API key
        const picker = new google.picker.PickerBuilder()
            .addView(google.picker.ViewId.DOCS)
            .setOAuthToken(accessToken)
            .setDeveloperKey('AIzaSyBMiycIvEdZbP0hX02Zz5SkcfuHjXB6l8A')
            .setCallback(pickerCallback)
            .build();
        picker.setVisible(true);
    }

    tokenClient.callback = async (response) => {
        if (response.error !== undefined) {
            throw (response);
        }
        accessToken = response.access_token;
        showPicker();
    };

    if (accessToken === null) {
        tokenClient.requestAccessToken({prompt: 'consent'});
    } else {
        tokenClient.requestAccessToken({prompt: ''});
    }
}
function pickerCallback(data) {
    let fileUrl = 'nothing';
    if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
        let doc = data[google.picker.Response.DOCUMENTS][0];
        console.log(doc);
        fileUrl = doc[google.picker.Document.URL];
    }
}
document.getElementById('googleDrive').addEventListener('click', function () {
    createPicker();
});